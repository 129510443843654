


.mapboxgl-ctrl-bottom-right {
    display: none!important;
}

.color_dot {
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    float: left;
}

#map {
    position: absolute;
    width: 100%;
    height: calc(100% - var(--safe-area-inset-bottom) - var(--tabbar_height));
    left: 0;
    top: 0;
}

.vote-card {
    background-color: var(--header_alternate_background);
    margin-bottom: 5px;
}

#all-polls-card {
    background-color: #47a3ff;
    color: #fff;
}

.vote-card-title {
    margin-right: 120px;
    padding: 12px;
}

.progress-bar {
    border-radius: 4px;
    background-color: var(--content_tint_background);
    overflow: auto;
    position: relative;
}

.progress-bar-text {
    line-height: 30px;
    font-size: 13px;
    font-weight: 600;
    -webkit-font-smoothing: subpixel-antialiased;
    position: absolute;
    left: 6px;
}

.progress-bar-value {
    justify-self: right;
    line-height: 30px;
    font-size: 13px;
    font-weight: 600;
    -webkit-font-smoothing: subpixel-antialiased;
    position: absolute;
    right: 6px;

}

.progress-bar-value > div{
    float: left;
    padding-top: 7px;
    padding-right: 5px;

}
.progress-bar-value > span{
    float: left;
}

#home-card-grid {
    padding-left: 8px!important;
    padding-right: 8px!important;
    margin-top: calc(var(--safe-area-inset-top) + 0px);
}

#polls .Panel__in {
    background-color: var(--content_tint_background)!important;
}

.caption{
    color: var(--text_subhead);
    margin-top: 5px;
    text-align: center;
}


#first.mainDiv{
    color: var(--text_primary);
    position: absolute; 
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    left:0;
    right:0;
    top:0;
    bottom:0;
    /* background: linear-gradient(#7EA2FF 0%, rgba(132, 167, 255, 0.953125) 77.23%, rgba(255, 255, 255, 0) 100%), #FFFFFF; */
    /*background: linear-gradient(#7EA2FF 0%, rgba(132, 167, 255, 0.953125) 18.23%, rgba(255, 255, 255, 0) 100%), #FFFFFF;*/
    background-color: var(--content_background);
    /* background-color: red;
    animation-name: example;
    animation-duration: 4s; */
    /*background-color: rgb(183, 142, 250);*/
    /*animation-name: background_color_1;
    animation-duration: 4s; 
    animation-fill-mode: forwards;
    animation-delay: 0.1s;*/
    /* animation-iteration-count: infinite; */
}

#first.mainDiv .animated-guide-icon {
    position: absolute;
    width: 100px;
    height: 100px;
    color: var(--accent);
}

#first.mainDiv .animated-guide-icon:nth-child(1){
    left: -5%;
    bottom: -100px;
    width: 75px;
    height: 75px;
    animation-delay: 0s;
    animation-duration: 11s;
    animation: animate-guide-icon 25s ease-in-out infinite;
}
#first.mainDiv .animated-guide-icon:nth-child(2){
    left: 5%;
    bottom: -150px;
    width: 75px;
    height: 75px;
    animation-delay: 0s;
    animation-duration: 11s;
    animation: animate-guide-icon 20s ease-in-out infinite;
}
#first.mainDiv .animated-guide-icon:nth-child(3){
    left: 15%;
    bottom: -300px;
    width: 75px;
    height: 75px;
    animation-delay: 0s;
    animation-duration: 11s;
    animation: animate-guide-icon 30s ease-in-out infinite;
}
#first.mainDiv .animated-guide-icon:nth-child(4){
    left: 25%;
    bottom: -200px;
    width: 75px;
    height: 75px;
    animation-delay: 0s;
    animation-duration: 11s;
    animation: animate-guide-icon 15s ease-in-out infinite;
}
#first.mainDiv .animated-guide-icon:nth-child(5){
    left: 35%;
    bottom: -250px;
    width: 75px;
    height: 75px;
    animation-delay: 0s;
    animation-duration: 11s;
    animation: animate-guide-icon 17s ease-in-out infinite;
}
#first.mainDiv .animated-guide-icon:nth-child(6){
    left: 45%;
    bottom: -60px;
    width: 75px;
    height: 75px;
    animation-delay: 0s;
    animation-duration: 11s;
    animation: animate-guide-icon 30s ease-in-out infinite;
}
#first.mainDiv .animated-guide-icon:nth-child(7){
    left: 55%;
    bottom: -75px;
    width: 75px;
    height: 75px;
    animation-delay: 0s;
    animation-duration: 11s;
    animation: animate-guide-icon 26s ease-in-out infinite;
}
#first.mainDiv .animated-guide-icon:nth-child(8){
    left: 65%;
    bottom: -160px;
    width: 75px;
    height: 75px;
    animation-delay: 0s;
    animation-duration: 11s;
    animation: animate-guide-icon 28s ease-in-out infinite;
}
#first.mainDiv .animated-guide-icon:nth-child(9){
    left: 75%;
    bottom: -90px;
    width: 75px;
    height: 75px;
    animation-delay: 0s;
    animation-duration: 11s;
    animation: animate-guide-icon 22s ease-in-out infinite;
}
#first.mainDiv .animated-guide-icon:nth-child(10){
    left: 85%;
    bottom: -80px;
    width: 75px;
    height: 75px;
    animation-delay: 0s;
    animation-duration: 11s;
    animation: animate-guide-icon 29s ease-in-out infinite;
}

@keyframes animate-guide-icon {

    0%{
        transform: translateY(0) rotate(0deg);
    }

    50%{
        transform: translateY(-750px) rotate(385deg);
    }

    100%{
        transform: translateY(0) rotate(720deg);
    }

}

.element_for_hide{
    color: white;
    position: absolute; 
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: linear-gradient(#7EA2FF 0%, rgba(132, 167, 255, 0.953125) 18.23%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    background-color: rgb(141, 29, 216);
    animation-name: hide_element;
    animation-duration: 0.2s; 
}

.title_guide{
    font-size: 32px;
    line-height: 1.5;
    text-align: center;
    z-index: 1;
}

.description{
    text-align: center;
    margin-top: 50px;
    animation-fill-mode: forwards;
    animation-name: show_element;
    animation-duration: 2s; 
    visibility: hidden;
    animation-delay: 0.1s;
}

.skip_guide{
    margin-top: 10px;
    color: white;
}

.buttons{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    animation-fill-mode: forwards;
    animation-name: show_element;
    animation-duration: 2s; 
    visibility: hidden;
    animation-delay: 0.1s;
}

.dynamicName{
    padding-top: 10px;
}

.progress-bar-background {
    transition: 0.25s;
}

.answer-cell .Cell__main{
    padding-right: 0;
    padding-top: 0;
}

.answer-cell .Cell__aside{
    padding-top: 0;
}

.answer-cell .Input{
    margin: 0!important;
}

.title-cell .Cell__main{
    padding-right: 46px;
    padding-top: 0;
}

.title-cell .Input{
    margin: 0!important;
}


.answer-cell .color_dot{
    margin-left: 16px;
}


@keyframes background_color_1 {
    0%   {background-color: rgb(183, 142, 250);}
    100% {background-color: rgb(141, 29, 216);}
  }

@keyframes show_element {
    0%   { opacity: 0;
        visibility: visible; }
    100% { opacity: 1;
    visibility: visible; }
}

@keyframes hide_element {
    100%   {
        transform: translateY(10px);
        opacity: 10%;
    }
}